const ROOTS_AUTH = '/auth';
const ROOTS_APP = '/';
export const ROOTS_TEST = '/test';
export const PATH_AFTER_LOGIN = '/';

// -----------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verifyOTP: path(ROOTS_AUTH, '/verify'),
  logout: '/logout',
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const URLS = {
  root: ROOTS_APP,
 
  test: {
    root: ROOTS_TEST,
    components: path(ROOTS_TEST, '/components'),
    allComponents: path(ROOTS_TEST, '/all-components'),
    pages: path(ROOTS_TEST, '/pages'),
  },


};

export const PATH_DOCS = '';


function path(root, sublink) {
  return `${root}${sublink}`;
}
