import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// import { config } from '../configs';

// components
import Loadable from '../components/micro/Loadable';
import testRoutes from './test';
// import NoData from '../components/micro/NoData';

// layouts
const LogoOnlyLayout = Loadable(lazy(() => import('../layouts/LogoOnlyLayout')));
const LandingLayout = Loadable(lazy(() => import('../layouts/LandingLayout')));
const TermsLayout = Loadable(lazy(() => import('../layouts/TermsLayout')));

// -----------------------------------------------

export default function Router() {
  return useRoutes(
    [
      {
        path: '',
        element: (
          <LandingLayout />
        ),
        children: [
          { path: '/', element: <LandingPage />, },
        ],
      },

      // terms
      {
        path: '',
        element: (
          <TermsLayout />
        ),
        children: [
          { path: 'terms', element: <Terms />, },
          { path: 'privacy', element: <Privacy />, },
        ],
      },

      // Main Routes
      {
        path: '*',
        element: <LogoOnlyLayout />,
        children: [
          { path: '404', element: <NotFound /> },
          { path: '*', element: <Navigate to="/404" replace /> },
          { path: 'zapx', element: <ZapxFigma /> },
        ],
      },


      { path: '*', element: <Navigate to="/404" replace /> },

      ...testRoutes,
    ]
  );


}

// IMPORT COMPONENTS

// For anonymous user
const LandingPage = Loadable(lazy(() => import('../pages/landing')));
const ZapxFigma = Loadable(lazy(() => import('../pages/zapxfigma')));
const Terms = Loadable(lazy(() => import('../pages/terms')));
const Privacy = Loadable(lazy(() => import('../pages/privacy')));

// Main
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
