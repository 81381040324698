import { alpha } from '@mui/material/styles';

// -----------------------------------------------

export default function TextField(theme) {
  const focusedColor = theme.palette.secondary.main;

  return {
    MuiTextField: {
      styleOverrides: {
        // uncomment to change border color in textarea on focus
        root: {
          '& .MuiInputLabel-root.Mui-focused:not(.Mui-error)': {
            color: focusedColor,
          },
          '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.background.paper,

            // '& fieldset': {
            //   borderColor: 'transparent'// theme.palette.grey[300]
            // },
            '&:not(.Mui-error):hover fieldset': {
              borderColor: theme.palette.grey[600]
            },

            '&.Mui-focused:not(.Mui-error) fieldset': {
              borderColor: focusedColor,
            },
            '&.Mui-focused:not(.Mui-error):hover fieldset': {
              borderColor: focusedColor,
            },
          },

          '& .MuiFilledInput-root': {
            backgroundColor: alpha(theme.palette.grey[900], 0.04),
            borderRadius: '8px',
            overflow: 'hidden',

            '&.MuiFilledInput-underline:before': {
              borderColor: "transparent"
            },
            '&.MuiFilledInput-underline:hover:before': {
              borderColor: "transparent"
            },

            '&.MuiFilledInput-underline:not(.Mui-error):after': {
              borderColor: focusedColor
            },

            '&.Mui-focused': {
              backgroundColor: alpha(theme.palette.grey[900], 0.09),
            },
          },

          '& .MuiInput-underline': {
            '&.MuiInput-root:hover:before': {
              borderColor: theme.palette.grey[600]
            },
            '&.MuiInput-root:not(.Mui-error):after': {
              borderColor: focusedColor
            },
          },
        },
      },
    },
  };
}
