import { alpha } from '@mui/material/styles';

// -----------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
  lighter: alpha('#EE7A55', 0.15),
  light: '#FFA082',
  // main: '#FF8862',
  main: '#FC7F4F',
  dark: '#F96E',
  darker: '#C1360A',
};
const SECONDARY = {
  lighter: alpha('#92A3FF', 0.15),
  light: '#A0AEF8',
  main: '#8595E6',
  dark: '#7182DE',
  darker: '#2B42B8',
};

// const PWA_PRIMARY = {
//   lighter: '#ffb29a',
//   light: '#ff9f81',
//   main: '#8595E6',
//   dark: '#b25f44',
//   darker: '#7c422f',
// };

// const PWA_SECONDARY = {
//   lighter: '#92a3ff2b',
//   light: '#9daaeb',
//   main: '#8595E6',
//   dark: '#5d68a1',
//   darker: '#414870',
// };

const INFO = {
  lighter: alpha('#338eff', 0.08),
  light: '#338eff',
  main: '#338eff',
  dark: '#006ffa',
  darker: '#0056c2',
};
const SUCCESS = {
  lighter: alpha('#07f2cb', 0.08),
  light: '#51fade',
  main: '#00e0bb',
  dark: '#06caaa',
  darker: '#05a388',
};
const WARNING = {
  lighter: alpha('#FFC01F', 0.1),
  light: '#ffcf7b',
  main: '#FFCB13',
  dark: '#ffa300',
  darker: '#846140',
};
const ERROR = {
  lighter: alpha('#f73365', 0.08),
  light: '#fa82a0',
  main: '#f73365',
  dark: '#f50c47',
  darker: '#d0083b',
};

const GREY = {
  0: '#F7F8FA',
  100: '#FFFFFF',
  200: '#EBEDF2',
  300: '#D3D6E1',
  400: '#B8BCCE',
  500: '#858aa1',
  600: '#6e748f',
  700: '#5c6178',
  800: '#4b4f61',
  900: '#393c4a',
  500_8: alpha('#858aa1', 0.08),
  500_12: alpha('#858aa1', 0.12),
  500_16: alpha('#858aa1', 0.16),
  500_24: alpha('#858aa1', 0.24),
  500_32: alpha('#858aa1', 0.32),
  500_48: alpha('#858aa1', 0.48),
  500_56: alpha('#858aa1', 0.56),
  500_80: alpha('#858aa1', 0.8),
};

const GREY_DARK = {
  0: '#2E364B',
  100: '#39435D',
  200: '#465378',
  300: '#52628B',
  400: '#6877A1',
  500: '#929cb7',
  600: '#b7bdcf',
  700: '#c9cedc',
  800: '#dcdfe8',
  900: '#fdfefe',
  500_8: alpha('#929cb7', 0.08),
  500_12: alpha('#929cb7', 0.12),
  500_16: alpha('#929cb7', 0.16),
  500_24: alpha('#929cb7', 0.24),
  500_32: alpha('#929cb7', 0.32),
  500_48: alpha('#929cb7', 0.48),
  500_56: alpha('#929cb7', 0.56),
  500_80: alpha('#929cb7', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },

  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: '#fff' },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },

  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    grey: GREY,
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[400] },
    background: { paper: GREY[100], default: GREY[0], neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
    themecontrast: '#172734'
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    grey: GREY_DARK,
    text: { primary: GREY_DARK[900], secondary: GREY_DARK[600], disabled: GREY_DARK[500] },
    background: { paper: GREY_DARK[100], default: GREY_DARK[0], neutral: GREY_DARK[200] },
    action: { active: GREY_DARK[600], ...COMMON.action },
    themecontrast: '#fff'
  },
};

export default palette;
