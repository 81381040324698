import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// guards

// components
import Loadable from '../components/micro/Loadable';
import { config } from '../configs';
import { URLS } from './paths';

// layouts
const GuestLayout = Loadable(lazy(() => import('../layouts/GuestLayout')));

// Test files
const TestComponents = Loadable(lazy(() => import('../pages/_test/Components')));
const AllTestComponents = Loadable(lazy(() => import('../pages/_test/AllComponents')));
const TestPages = Loadable(lazy(() => import('../pages/_test/Pages')));


// -----------------------------------------------


const testRoutes = config.env === 'production' ? [] : [{
  path: 'test',
  element:
    // <AuthGuard>
    <GuestLayout options={['logo', 'scan']} />
  // </AuthGuard>
  ,
  children: [
    { element: <Navigate to={URLS.test.allComponents} replace />, index: true },
    { path: 'components', element: <TestComponents /> },
    { path: 'all-components', element: <AllTestComponents /> },
    { path: 'pages', element: <TestPages /> },
  ],
}];


export default testRoutes;
