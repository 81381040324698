import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';

// hooks
import useLocalStorage from '../hooks/useLocalStorage';
import useResponsive from '../hooks/useResponsive';
// utils
import getColorPresets, { colorPresets, defaultPreset } from '../utils/getColorPresets';
// config
import { defaultSettings } from '../configs';

// -----------------------------------------------

const initialState = {
  ...defaultSettings,
  onChangeMode: () => { },
  onToggleMode: () => { },
  onChangeDirection: () => { },
  onChangeColor: () => { },
  onToggleStretch: () => { },
  onChangeLayout: () => { },
  onResetSetting: () => { },
  setColor: defaultPreset,
  colorOption: [],

  // -----collapse menu-----
  collapseClick: false,
  collapseHover: false,
  onToggleCollapse: () => { },
  // add collapse
  onHoverEnter: () => { },
  onHoverLeave: () => { },
  // -----collapse menu-----

};

const SettingsContext = createContext(initialState);

// -----------------------------------------------

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

function SettingsProvider({ children }) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    themeDirection: initialState.themeDirection,
    themeColorPresets: initialState.themeColorPresets,
    themeStretch: initialState.themeStretch,
    themeLayout: initialState.themeLayout,
  });

  const onChangeMode = (event) => {
    setSettings({
      ...settings,
      themeMode: event.target.value,
    });
  };

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
    });
  };

  const onChangeDirection = (event) => {
    setSettings({
      ...settings,
      themeDirection: event.target.value,
    });
  };

  const onChangeColor = (event) => {
    setSettings({
      ...settings,
      themeColorPresets: event.target.value,
    });
  };

  const onChangeLayout = (event) => {
    setSettings({
      ...settings,
      themeLayout: event.target.value,
    });
  };

  const onToggleStretch = () => {
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch,
    });
  };

  const onResetSetting = () => {
    setSettings({
      themeMode: initialState.themeMode,
      themeLayout: initialState.themeLayout,
      themeStretch: initialState.themeStretch,
      themeDirection: initialState.themeDirection,
      themeColorPresets: initialState.themeColorPresets,
    });
  };




  // -----collapse menu-----
  const isDesktop = useResponsive('up', 'lg');

  const [collapse, setCollapse] = useState({
    click: false,
    hover: false,
  });

  useEffect(() => {
    if (!isDesktop) {
      setCollapse({
        click: false,
        hover: false,
      });
    }
  }, [isDesktop]);

  const handleToggleCollapse = () => {
    setCollapse({ ...collapse, click: !collapse.click });
  };

  const handleHoverEnter = () => {
    if (collapse.click) {
      setCollapse({ ...collapse, hover: true });
    }
  };

  const handleHoverLeave = () => {
    setCollapse({ ...collapse, hover: false });
  };
  // -----collapse menu-----

  const settingsContextProviderValue = useMemo(() =>
  ({
    ...settings,
    // Mode
    onChangeMode,
    onToggleMode,
    // Direction
    onChangeDirection,
    // Color
    onChangeColor,
    setColor: getColorPresets(settings.themeColorPresets),
    colorOption: colorPresets.map((color) => ({
      name: color.name,
      value: color.main,
    })),
    // Stretch
    onToggleStretch,
    // Navbar Horizontal
    onChangeLayout,
    // Reset Setting
    onResetSetting,

    // -----collapse menu-----
    isCollapse: collapse.click && !collapse.hover,
    collapseClick: collapse.click,
    collapseHover: collapse.hover,
    onToggleCollapse: handleToggleCollapse,
    onHoverEnter: handleHoverEnter,
    onHoverLeave: handleHoverLeave,
  })
    // eslint-disable-next-line
    , [
      settings,
      // -----collapse menu-----
      collapse
    ])

  return (
    <SettingsContext.Provider
      value={settingsContextProviderValue}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
