// @mui
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
// mui theme
import ThemeProvider from './theme';
// routes
import Router from './routes';
// components
import ScrollToTop from './components/micro/ScrollToTop';
import NotistackProvider from './components/others/NotistackProvider';
import Settings from './components/settings';
// contexts


// javascript-time-ago initialized
TimeAgo.addDefaultLocale(en)

// -----------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <NotistackProvider>
        <Settings />
        <ScrollToTop />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router />
        </LocalizationProvider>
      </NotistackProvider>
    </ThemeProvider>
  );
}